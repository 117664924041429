import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "70px 0 70px 0",
	"sm-padding": "40px 0 40px 0",
	"quarkly-title": "Footer-20"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"color": "--darkL1",
			"font": "normal 600 28px/1.2 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0 30px 0px",
			"sm-margin": "0px 0 5px 0px",
			"text-align": "center",
			"children": "Eventful Horizons"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-flex-wrap": "wrap",
			"md-margin": "0px 0px 35px 0px",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--darkL2",
			"margin": "0px 6px 0px 0px",
			"md-margin": "0px 6px 0px 6px",
			"href": "/index",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"padding": "6px 12px 6px 12px",
			"text-transform": "uppercase",
			"children": "Головна"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 6px 0px 6px",
			"href": "/service",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"padding": "6px 12px 6px 12px",
			"text-transform": "uppercase",
			"children": "Послуги"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 6px 0px 6px",
			"href": "/contacts",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"padding": "6px 12px 6px 12px",
			"text-transform": "uppercase",
			"children": "Контакти"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" align-items="center" justify-content="center" />
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;